.members {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
}

.member {
  outline: solid 1px var(--color-pastel-pink);
  border-radius: 1px;
  width: 370px;
}

.nickname, .bio {
  color: var(--color-blast-of-bronze);
}
