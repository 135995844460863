.header {
  display: flex;
  justify-content: center;
  position: sticky;
  top: 0;
  width: 100%;
  background-color: var(--color-rich-black70);
  z-index: 2;
}

.item {
  margin: 10px;
}

.item a {
  color: var(--color-rufous);
  text-decoration: none;
}
