.image {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.title {
  position: absolute;
  bottom: 10px;
  left: 20px;
  color: var(--color-rufous);
  font-family: var(--font-family-extra);
  font-size: 6em;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: var(--color-rich-black70);
  pointer-events: none;
  cursor: default;
}
